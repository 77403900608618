.App {
  text-align: center;
  	margin-left:30px;
	margin-right:10px;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {

  min-height: 0vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.modal-backdrop {
    opacity: 0.5;
}

.modal.fade.in {
    opacity: 1;
}

.modal.fade.in .modal-dialog {
    transform: translate(0,0);
}

.btnStyle {
  background: none!important;
  border: none;
  padding: 0!important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
 
.imgStyle{
	margin-left: auto;
	margin-right: auto;
	width:250px;
	height:250px 
}

.logoStyle{
	width:150px;
}

.headerText {
  position:center;
}

.theaderStyle {
  background-color: "";
  color: black;
  padding: 0px;
  text-align: center;
}
table,th,td{
	border: 1px solid black;
}

.tableStyle{
	border-collapse: "";
	width:99%;
	display: inline-table;
}

.tableDiv{
	height: 300px;
	overflow: auto;
	font-size: 13px;
}

.pStyle{
	text-align: left;
	font-size: 12px;
}
.addressDiv{
	text-align: left;
	font-size: 14px;
}

.error{
	font-size: 13px;
}

.uploadDivStyle{
	border-radius: 2px;
    border-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.87);
    background-color: white;
    padding: 15px 10px;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
	width:45%;
	margin-left:350px;
}


.pagination {
    margin: 25px auto;
    display: flex;
    list-style: none;
    outline: none;
	padding-left: 0px;

}
.pagination > .active > a{
    background-color: #47ccde ;
    border-color: #47ccde ;
    color: #fff;
	font-size: 14px;
	
}
.pagination > li > a{
    border: 1px solid black ;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
	font-size: 14px;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #47ccde ;
    border-color: #47ccde;
    outline: none ;
}
.pagination > li > a, .pagination > li > span{
    color: ""
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
}
